#ReplaceAudioModal ul {
  margin: -30px 0px 0px 0px;
  padding: 0;
}
#ReplaceAudioModal ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 3em;
}

#ReplaceAudioModal .audio-drop-area {
  border: solid 1px #ccc;
  width: 100%;
  height: 190px;
  background-color: #d9f3fe;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
#ReplaceAudioModal .audio-drop-area span {
  color: #999;
  cursor: pointer;
}
