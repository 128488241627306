/* Audio Files & Track Information */

.audio-drop-area {
  border: solid 1px #ccc;
  width: 100%;
  height: 190px;
  background-color: #d9f3fe;
}

.audio-drop-area span {
  display: block;
  margin: 0 auto;
  padding-top: 66px;
  color: #999;
  text-align: center;
}

.track-draggable-area {
  background-color: #d9f3fe;
  border: dashed 1px #ccc;
  padding: 0.3rem 0.6rem;
  vertical-align: top;
}

.draggable-track {
  border-radius: 2px;
  background-color: #fff;
  margin: 7px 0;
  padding: 6px;
  cursor: grab;
}

input[type='file'] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.sortable-audio-file {
  width: 100%;
  padding: 6px;
  border-radius: 2px;
  box-shadow: none !important;
  background-color: #fff;
  display: inline-flex;
  vertical-align: middle;
  border: 1px dashed #ccc;
  cursor: grab;
}

.sortable-audio-file .material-icons {
  padding-right: 10px;
}

.sortable-audio-file span {
  line-height: 24px;
}

.table-section {
  display: flex;
  flex-direction: column;
  margin: 0px 40px 40px 40px;
}

.table-section .nav-tabs {
  border-bottom: none;
}

.table-section .nav-link:first-child {
  margin-left: 30px;
}

.table-section .nav-link.active {
  border: none;
}

.table-section .tab-pane {
  background-color: #fff;
  padding: 30px;
}

td.audio-file {
  background-color: #d9f3fe;
}

.droppable {
  height: 30px;
}

.upc-uploader-audio button {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dotted #00589b;
}

.upc-uploader-audio button i {
  color: #00589b;
  padding-right: 10px;
}

button.btn-disabled.btn.btn-secondary.action {
  color: #817b7b;
}

.aud-fle-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 2px !important;
}

.uploading-pgs-loader {
  display: inline-block;
}

.audio-footer-action-fxd {
  position: fixed;
  width: 83.7%;
  bottom: 0px;
  right: 8%;
  background: #fff;
  padding: 8px 8px;
  z-index: 9999;
}

.fixed-table-header {
  position: 'sticky';
  top: '240px';
  background: '#fff';
}
.table thead .tbl-isrc-width {
  width: 155px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.tbl-isrc-width-td {
  width: 155px !important;
}

.table thead .release-date-col {
  width: 190px;
}
