@charset "utf-8";
/* CSS Document */

header {
  display: block;
  position: fixed;
  z-index: 1000;
  width: 100%;
  color: #fff;
  height: 240px;
  background-color: #333;
  background-image: url(../images/new-header-bg.png);
  background-size: cover;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.content {
  margin-top: 280px;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

header.compact {
  height: 156px;
}

.content.compact {
  margin-top: 196px;
}

.nav-bg {
  display: block;
  height: 50px;
  width: 100%;
  background-color: #000;
  opacity: 0.7;
  position: absolute;
  top: 0px;
}

header nav {
  display: block;
  height: 50px;
  font-size: 15px;
}

header nav ul {
  list-style: none;
}

header nav ul li {
  color: #fff;
  display: inline-block;
  /* margin: 14px 40px auto auto; */
  font-family: 'Roboto Condensed', sans-serif;
}

header nav ul li:last-child {
  margin-right: 0px;
}

header nav ul li a,
header nav ul li a:visited {
  display: block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-family: 'Roboto Condensed', sans-serif;
}

header nav ul li a:hover {
  color: #dc2c46;
  text-decoration: none;
}

header nav ul li div.dropdown button.btn.btn-secondary.dropdown-toggle {
  margin-top: -3px;
  padding: 0px 6px;
  background-color: transparent;
  color: #fff;
}

header nav ul li div.dropdown .dropdown-menu {
  margin-top: 0px;
  box-shadow: 2px 3px 8px #000;
  background-color: #000;
}

header nav ul li div.dropdown .dropdown-item {
  color: #fff;
}

span.guardian-logo {
  display: block;
  position: absolute;
  z-index: 10;
  width: 134px;
  height: 122px;
  top: 18px;
  left: -3%;
  background-image: url(../images/guardian-logo.png);
  background-size: contain;
}

div.row.d-flex.project-title {
  display: block;
  margin: 20px auto;
}

div.row.d-flex.project-title div.col-10.align-self-start h1 {
  margin-left: 40px;
  text-shadow: 3px 3px 3px #000;
}

div.row.d-flex.project-title div.col-2 {
  text-align: right;
}

div.row.d-flex.steps-bar {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

div.row.d-flex.steps-bar ul {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

div.row.d-flex.steps-bar ul li {
  display: inline-block;
  text-align: center;
  width: 80px;
}

div.row.d-flex.steps-bar ul li a {
  display: block;
  color: #fff !important;
}

div.row.d-flex.steps-bar ul li a:hover {
  text-decoration: none !important;
}

div.row.d-flex.steps-bar ul li a:hover span.step-description {
  font-size: 1em;
  text-decoration: none !important;
  color: #fff !important;
  text-shadow: 3px 3px 3px #000;
}

div.row.d-flex.steps-bar ul li span {
  text-align: center;
  vertical-align: center;
}

div.row.d-flex.steps-bar ul li span.step-description {
  font-size: 1em;
  margin: auto auto 10px auto;
  text-shadow: 3px 3px 3px #000;
}

div.row.d-flex.steps-bar ul li a span.step {
  display: block;
  position: relative;
  z-index: 10;
  margin: 10px auto -20px auto;
  padding-top: 3px;
  border-radius: 100%;
  height: 34px;
  width: 34px;
  background-color: #01579b;
  color: #fff;
  font-size: 18px;
}

div.row.d-flex.steps-bar ul li a span.step i {
  font-weight: 700;
  margin-top: 2px;
}

div.row.d-flex.steps-bar ul li a.active span.step {
  background-color: #fff;
  color: #01579b;
}

div.row.d-flex.steps-bar ul li a:hover span.step,
div.row.d-flex.steps-bar ul li a span.step.past {
  background-color: #fff;
  color: #01579b;
}

div.row.d-flex.steps-bar ul li.step-bar {
  display: inline-block;
  width: 9%;
  height: 5px;
  margin: 44px -35px auto -35px;
  background-color: #01579b;
}

div.row.d-flex.steps-bar ul li.step-bar.past {
  background-color: #fff;
}

div.row.d-flex.steps-bar ul li a span.step-arrow {
  display: block;
  margin: 30px auto 0px auto;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

div.row.d-flex.steps-bar ul li a.active span.step-arrow {
  display: block;
  margin: 30px auto 0px auto;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
}

.notify-wrapper {
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  background: #333333;
  padding: 4px;
}

.notify-wrapper span {
  position: absolute;
  background: #ff25a8;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
  left: 25px;
  top: -8px;
}

.notify-wrapper i {
  font-size: 20px;
}

.notification-li {
  position: relative;
}

.notification-content {
  position: absolute;
  background: #f5f5f5;
  left: 20px;
  border-radius: 2px;
  top: 36px;
  min-width: 366px;
  box-shadow: 0px 1px 2px 2px #dddddd;
  color: #000;
  z-index: 999;
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.notification-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.content-divider {
  display: flex;
  align-items: center;
}

.lft-col {
  width: 22%;
}

.lft-col span {
  padding: 10px;
  background: red;
  border-radius: 50px;
  color: fff;
  font-weight: bold;
}

.rgt-col p {
  padding-left: 6px;
  font-size: 12.5px;
  color: #000;
  margin: 0px;
  text-align: left;
}

.bold {
  font-weight: bold;
}

.content-list {
  padding: 2px;
  color: #000;
}

.content-divider {
  display: flex;
  align-items: center;
}

.lft-col {
  width: 22%;
}

.lft-col span {
  padding: 12px 12px;
  border-radius: 100%;
  color: fff;
  width: 45px;
  display: inline-block;
  font-weight: bold;
}

.content-list li {
  margin: 0px;
  padding: 10px;
}

.bold {
  font-weight: bold;
}

.menu-items li i.material-icons {
  display: inline-flex;
  vertical-align: top;
}

.lang-dropdown button#dropdown-basic {
  background: #333333;
  border: none;
  color: #fff;
  border-radius: 17px;
  padding: 4px 10px;
  outline: none;
  box-shadow: none !important;
}

.lang-dropdown img.lang-img {
  width: 22px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.nav-header-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1600px) and (min-width: 1024px) {
  .custom-col-width .custom-col-2 {
    max-width: 18%;
    flex: 0 0 18%;
  }

  .custom-col-width .custom-col-8 {
    max-width: 70%;
    flex: 0 0 70%;
  }
}
