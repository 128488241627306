.album-art-drop {
  border: solid 1px #ccc;
  background-color: #d9f3fe;
  height: 190px;
  width: 190px;
  /* top: 50px; */
  position: relative;
}

.album-art-drop span {
  display: block;
  margin: 0 auto;
  padding-top: 66px;
  color: #999;
  text-align: center;
}

.cover-art label {
  vertical-align: top;
  padding-top: 5px;
}

.notes-row label {
  vertical-align: top;
}

.notes-row > textarea {
  vertical-align: top;
  height: 120px;
}

label.custom-checkbox {
  display: inline-block;
  vertical-align: middle;
}

.col-form-label.col-2.tbd {
  margin-left: 30px;
  margin-right: -20px;
}

.col-3.notes.form-label {
  vertical-align: top;
}

input[type='file'] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

img.obj {
  position: absolute;
  top: 0;
  left: 0;
}

div.browse-btn {
  display: block;
  width: 100px;
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 190px;
  margin-top: 64px;
  margin-left: -13px;
  text-align: center;
  cursor: pointer;
}

div.browse-btn span {
  position: absolute;
  width: 180px;
  padding: 8px;
  cursor: pointer;
}

div.browse-btn input {
  position: relative;
  height: 36px !important;
  width: 84px !important;
  padding: 8px;
  cursor: pointer;
}

button.btn.btn-secondary.action.remove-art {
  position: absolute;
  right: 2px;
  z-index: 999;
  opacity: 0.9;
  border: solid 1px #ced4da;
}

.release-date {
  position: relative;
}

.release-date .react-datepicker-wrapper {
  width: 50%;
}

.custom-date-picker {
  border: 1px solid #ddd;
  padding: 4px;
  display: flex;
  justify-content: space-between;
}

.custom-date-picker input {
  border: none;
  width: 100%;
  padding: 3px;
  outline: none;
}

.custom-date-picker input:disabled {
  background: #e5e5e5e3;
  box-shadow: 0px 0px 1px 4px #e5e5e5e3;
  color: #817d7d;
}

.custom-date-picker .material-icons.disabled {
  color: #e5e5e5e3;
  background: #fff;
  box-shadow: 0px 0px 1px 4px #e5e5e5e3;
}

.react-datepicker-popper {
  z-index: 9999;
}

.custom-date-picker input.is-invalid {
  border: 1px solid #dc3545;
}

.custom-date-picker .invalid-tooltip {
  right: 30px;
}

.multi-select-search-input {
  width: 90%;
  padding: 8px;
  margin: 2px 5%;
  text-align: left !important;
}

.cover-art-label {
  /* flex-grow: 1; */
  margin-right: 25px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.custom-radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.custom-radio-wrapper .custom-radio {
  margin: 0 5px 0 0;
  width: 100px;
  height: 45px;
  position: relative;
}

.custom-radio-wrapper label,
.custom-radio-wrapper input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.custom-radio-wrapper input[type='radio'] {
  opacity: 0.011;
  z-index: 100;
}

.custom-radio-wrapper input[type='radio']:checked + label {
  background: #01579b;
  color: #fff;
}

.custom-radio-wrapper label {
  padding: 5px;
  cursor: pointer;
  z-index: 90;
  background: #f5f5f5;
  color: #01579b;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
}

.custom-radio-wrapper .custom-radio label:hover {
  background: #01579b !important;
}

.add-cont-cpy-wrap .copy {
  margin-left: 10px;
  cursor: pointer;
  color: #727070;
}
.mb-70 {
  margin-bottom: 70px;
}

.justify-end {
  justify-content: end;
}

.release-date-options {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: -86px;
}

.release-date-op {
  display: flex;
  align-items: center;
  /* flex: 1; */
}
.release-date-op input {
  flex: 1;
}

.release-date-op input:active,
.release-date-op input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.release-date-op label {
  margin-bottom: 0px !important;
}

.release-date-op button.tooltip-btn.btn.btn-primary {
  margin-left: 6px !important;
  margin-top: 0px !important;
}

.release-date-op input[type='radio'] {
  margin-right: 6px;
}

.psi-artist-wrapper {
  min-width: 350px;
  max-height: 301px;
  border: 1px solid #ddd;
  position: absolute;
  background: #f5f5f5;
  z-index: 999;
  top: 35px;
  left: 0px;
  overflow: scroll;
}
.pos-releative {
  position: relative !important;
}

.psi-artist-item {
  background: #fff;
  padding: 10px;
  margin: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-name-detl {
  display: flex;
  align-items: center;
}
.art-details {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

.art-item-gray-col {
  color: #666666;
}

.art-details span {
  font-weight: bold;
  font-size: 15px;
}

.artist-input {
  flex: 1;
  background: #fff;
  border: none !important;
  outline: none;
  white-space: pre;
  box-shadow: none !important;
}

/* Skeleton Container */
.skeleton-container {
  display: flex;
  align-items: center;
  margin: 10px 15px;
  justify-content: flex-start;
}

/* Skeleton Image Placeholder */
.skeleton-image {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  /* border-radius: 50%; */
  background-color: #ccc; /* Placeholder color */
  margin-right: 10px; /* Adjust as needed */
}

/* Skeleton Text Placeholder */
.skeleton-text {
  width: 200px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: #ccc; /* Placeholder color */
}

/* Animation for a subtle pulse effect */
@keyframes pulse {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #ccc;
  }
}

/* Apply pulse animation to the skeleton */
.skeleton-container .skeleton-image,
.skeleton-container .skeleton-text {
  animation: pulse 1.5s infinite;
}
