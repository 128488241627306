.blk-plcy-table th:nth-child(1) {
  width: 27%;
}

.blk-plcy-table th:nth-child(2) {
  width: 17%;
}

.blk-plcy-table th:nth-child(3) {
  width: 5%;
}

.blk-plcy-table th:nth-child(4) {
  width: 10%;
}

.blk-plcy-table th:nth-child(5) {
  width: 15%;
}

.blk-plcy-table th:nth-child(6) {
  width: 16%;
}

.track-bg {
  background: #d9f3fe;
  position: relative;
}

.bp-tr-list {
  padding: 10px;
  background: #ffffff;
  margin: 10px 3px;
}

.drop-area {
  height: 248px;
  overflow: scroll;
  overflow-x: hidden;
}

.drop-area.video {
  height: 140px;
}

.drop-area::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.policy-lp {
  min-height: 50px;
}

.policy-lp > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
}

.light-gray-bg {
  background: #f5f5f5;
}

.light-gray-bg .react-datepicker__input-container {
  background: #f5f5f5;
}

.plt-img {
  min-width: 27%;
}

.monetize {
  min-width: 8%;
}

.blk-af-rel {
  min-width: 16%;
}

.duration {
  min-width: 24%;
}

.untill {
  min-width: 25%;
}

.bp-tr-list label,
.bp-tr-list .material-icons {
  margin-right: 10px;
}

.blk-plcy-table td {
  vertical-align: top;
  /* padding: 0px !important; */
}

.blk-plcy-table .form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.duration .form-control {
  height: 35px !important;
  width: 50%;
  border-radius: 4px;
  border-color: #c4c4c4;
}

.duration select.form-control:focus {
  background-color: #f5f5f5;
  border-color: #c4c4c4;
}

.untill .react-datepicker__input-container input {
  background-color: #f5f5f5;
}

.untill .react-datepicker__input-container {
  display: flex;
  justify-content: center;
}

.blocking-custom-d-picker {
  width: 50% !important;
  height: 35px;
  background-color: #f5f5f5;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}

.blk-plcy-table select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
}
.drag-track-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}
