div.loading-animation-bg {
  background-color: #000;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
div.loading-bars {
  width: 70px;
  margin: 20% auto;
  color: #fff;
  font-weight: 700;
}
