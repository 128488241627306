.wrapper-tr {
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.wrapper-td {
  background: #f5f5f5;
}

.close {
  cursor: pointer;
  background: #ffffff;
}

.ext-tracks-wrapper {
  max-height: 250px;
  overflow-y: auto;
}

.ext-tracks-table tr {
  background: #f5f5f5;
}

/* Overwrite hover property */
table.search-table tr.wrapper-tr:hover td {
  cursor: default;
  background-color: #f5f5f5 !important;
}

.white-bg,
.white-bg:hover {
  background-color: #ffffff !important;
}

.partner-img {
  padding-right: 10px;
}

table.search-table tr.wrapper-tr .dws-wrapper-tr:hover td {
  background-color: #ffff !important;
}
.dws-wrapper-tr td {
  padding: 10px !important;
}

.not-delivered {
  color: #a91414;
}
.dws-wrapper-tr td {
  height: 70px;
}
