span.instructions {
  display: block;
  padding: 20px 36px 16px 20px;
  font-size: 1.1em;
}

.accordion .card {
  border: none;
}

.accordion .card .card-header {
  border-bottom: solid 1px #fff;
  background-color: transparent;
}

.accordion .card .card-header button.btn.btn-button {
  font-size: 1em;
  color: #01579b;
  margin-left: -10px;
  background-color: #f5f5f5;
  border: solid 1px #ccc;
}

.accordion .card .card-header button.btn.btn-button:hover,
.accordion .card .card-header button.btn.btn-button:active,
.accordion .card .card-header button.btn.btn-button:focus,
.accordion .card .card-header button.btn.btn-button:visited {
  text-decoration: none;
}

.vd-thmb-box {
  background-image: url(./../../../images/video-thmb.svg);
  width: 321px;
  height: 202px;
}

.video-grid {
  margin-left: 16px;
  margin-bottom: 60px;
}

.video-grid h3 {
  margin-top: 14px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 0px;
}
