.message-wrapper {
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 10px 10px 10px 20px;
}

.message-wrapper strong {
  font-weight: bold;
}

.pad-lft-25 {
  padding-left: 25px !important;
}

.message-wrapper .close {
  background: #ffffff;
}

.each-message {
  width: 100%;
  display: flex;
  line-height: 28px;
  justify-content: start;
}

.message-name {
  margin-right: 10px;
  min-width: 34%;
  white-space: nowrap;
}

@media screen and (max-width: 1650px) and (min-width: 1550px) {
  .message-name {
    min-width: 36%;
  }
}

@media screen and (max-width: 1550px) and (min-width: 1450px) {
  .message-name {
    min-width: 38%;
  }
}

@media screen and (max-width: 1450px) and (min-width: 1350px) {
  .message-name {
    min-width: 42%;
  }
}

@media screen and (max-width: 1350px) and (min-width: 1150px) {
  .message-name {
    min-width: 48%;
  }
}

.comments-list {
  height: 150px;
  overflow: scroll;
  margin-bottom: 15px;
}

.inbox-table td {
  color: rgb(113 113 113);
}

.unread {
  background-color: rgba(0, 0, 0, 0.05);
}

.unread td {
  font-weight: bold;
  color: #000;
}
