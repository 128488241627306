/* Audio Files & Track Information */

.audio-drop-area {
  border: solid 1px #ccc;
  width: 100%;
  height: 190px;
  background-color: #d9f3fe;
}

.track-draggable-area {
  background-color: #d9f3fe;
  border: dashed 1px #ccc;
  padding: 0.3rem 0.6rem;
  vertical-align: top;
}

.draggable-track {
  border-radius: 2px;
  background-color: #fff;
  margin: 7px 0;
  padding: 6px;
  box-shadow: 2px 3px 8px #8c9396;
  cursor: grab;
}

.droppable-cell {
  height: 32px;
  border: dashed 1px #ccc;
  border-radius: 2px;
  padding: 6px;
  background-color: #f5f5f5;
}

.sortable-audio-file {
  width: 100%;
  padding: 6px;
  border-radius: 2px;
  background-color: #d9f3fe;
  display: inline-flex;
  vertical-align: middle;
  box-shadow: 2px 3px 8px #8c9396;
  cursor: grab;
}

.sortable-audio-file .material-icons {
  padding-right: 10px;
}

.sortable-audio-file span {
  line-height: 24px;
}

.table-section {
  display: flex;
  flex-direction: column;
  margin: 0px 40px 40px 40px;
}

.table-section .nav-tabs {
  border-bottom: none;
}

.table-section .nav-link:first-child {
  margin-left: 30px;
}

.table-section .nav-link.active {
  border: none;
}

.table-section .tab-pane {
  background-color: #fff;
  padding: 30px;
}

table.table.track-information button.btn.btn-secondary.action {
  margin-left: 5px;
}

.release-date-col > span {
  /* margin-left: 84px; */
}

.release-date-col label {
  /* margin-left: 20px; */
}

.react-datepicker__tab-loop,
.react-datepicker__tab-loop__start,
.react-datepicker__tab-loop__end {
  display: inline-block;
}

.tab-pane > .table-responsive {
  overflow-x: unset;
}
.table-responsive {
  overflow-x: unset;
}

.react-datepicker {
  display: flex;
}

span.checkmark.disabled {
  background: #a0a6aa;
}

.custom-checkbox:hover input ~ .checkmark.disabled {
  background-color: #ccc;
  border: solid 1px #ccc;
}
.video-track-table.tracks-table {
  min-height: 400px;
}

.video-track-table.tracks-table td {
  vertical-align: top !important;
}
