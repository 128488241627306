@keyframes slider {
  from {
    right: -200px;
  }
  to {
    right: 4;
  }
}

.comment-slider {
  box-shadow: 0px 1px 2px 2px #dddddd;
  padding: 10px;
  position: relative;
  height: 100%;
  background: #f5f5f5;
}

.comment-slider .close {
  display: flex;
  justify-content: flex-end;
  float: none;
}

.comment-slider ul {
  padding: 0;
  overflow: scroll;
  margin-bottom: 15px;
  overflow-x: hidden;
}

.comment-slider ul::-webkit-scrollbar {
  display: none;
}

.comment-slider ul li {
  list-style-type: none !important;
  margin: 8px auto;
  font-size: 13px;
}

.comment-slider ul li strong {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.comment-slider .close {
  position: absolute;
  right: 8px;
  top: 8px;
}

.react-draggable {
  z-index: 9999 !important;
  position: fixed !important;
}

.loader-img {
  text-align: center;
}

#commentForm {
  position: absolute;
  width: 92%;
  bottom: 10px;
}

.error_message {
  color: #dd3644;
  margin: 0px;
}
