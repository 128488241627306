.msh-wrapper {
  background: #ffffff;
  line-height: 3rem;
  /* width: 435px; */
  padding: 10px;
}

.requestFormInput .dropdown-toggle::after {
  position: absolute;
  top: 8px;
  left: 250px;
}
.releaseInfoInput .dropdown-toggle::after {
  position: absolute;
  right: 6px;
  top: 8px;
}

.msh-content {
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 12px;
  font-size: 0.9em;
}

label.custom-checkbox.msh-checkbox {
  width: 40px !important;
}
/* .company-wrapper{
  padding: 10px;;
} */

.divison-wrapper {
  padding-left: 30px;
}

.requestFormInput .dropdown-menu {
  margin-top: -4px;
  margin-left: -6px !important;
}
.releaseInfoInput .dropdown-menu {
  margin-top: -3px !important;
}
.ms-dropdown-wrapper .dropdown-menu {
  box-shadow: 2px 3px 8px #8c9396;
  max-height: 30rem;
  min-width: auto;
  overflow-y: scroll !important;
  overflow-x: hidden;
  transform: translate3d(5px, 35px, 0px) !important;
  /* width: 450px !important; */
  max-width: 600px;
  padding: 0 !important;
  margin-left: -14px;
  overflow: scroll !important;
}
.btn-tagList {
  display: grid;
  grid-template-columns: auto auto auto;
  width: max-content;
}
.ms-dropdown-wrapper .dropdown-menu.show::-webkit-scrollbar {
  display: inherit;
}

.ms-dropdown-wrapper .dropdown-menu::-webkit-scrollbar {
  width: 10px;
}

.ms-dropdown-wrapper .dropdown-menu::-webkit-scrollbar-track {
  background: #ffffff;
}

.ms-dropdown-wrapper .dropdown-menu::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}
.ms-dropdown-wrapper .dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.ms-dropdown-toggle {
  color: #495057 !important;
  background: #f4f5f5 !important;
  border-color: #ced4da !important;
}

.label-wrapper {
  padding-left: 30px;
  margin-top: -10px;
  width: max-content;
}

.msh-wrapper .sub-title,
.msh-wrapper .main-title {
  color: rgba(0, 0, 0, 0.7);
}

.msh-wrapper .main-title {
  font-size: 16px;
}
.msh-wrapper input {
  width: 324px !important;
  display: inherit !important;
  height: 35px !important;
}

.inside-wrapper {
  position: relative;
  width: max-content;
}
.search-icon {
  color: #999999 !important;
  font-size: 22px;
  top: 7px !important;
}

.tree {
  position: relative;
  margin-top: -10px;
}

.msh-wrapper .tree .vl {
  border-left: 2px solid #ddd;
  height: 25px;
  position: absolute;
  top: 0px;
  left: -18px;
  margin-top: 1px;
}

.msh-wrapper .tree .hl {
  border-bottom: 2px solid #ddd;
  width: 20px;
  display: inline-block;
  position: absolute;
  top: 24px;
  left: -17px;
}

.msh-wrapper .tree .ex-ht {
  height: 39px;
  top: -14px;
}

.msh-wrapper .material-icons.search-icon {
  display: inline-flex;
  vertical-align: top;
  position: absolute;
  top: 10px;
  right: 4px;
  color: rgba(0, 0, 0, 0.7);
}

.msh-parent-wrapper {
  display: flex;
}
.search-input {
  position: relative;
  max-width: max-content;
}

.rmvPadDivisonLabel {
  padding-left: 0px !important;
}
.rmvPadLabel {
  padding-left: 0px !important;
  padding-top: 10px !important;
}

@media (min-width: 576px) {
  div#RequestAccesModal.modal-dialog {
    max-width: 630px;
    margin: 1.75rem auto;
  }
}

.requestFormInput #dropdown-autoclose-inside {
  width: 280px !important;
  height: 38px;
  left: 0px;
  text-align: left;
  overflow: auto;
}

.releaseInfoInput #dropdown-autoclose-inside {
  width: 50% !important;
  height: 38px;
  left: 0px;
  margin-left: -8px;
  text-align: left;
  overflow: auto;
}

.userAdminModal #dropdown-autoclose-inside {
  width: 44% !important;
  height: 38px;
  left: 0px;
  margin-left: -8px;
  text-align: left;
  overflow: auto;
}
.userAdminModal .dropdown-menu {
  margin-top: -7px !important;
  margin-left: 0px;
}

.userAdminModal .dropdown-toggle::after {
  position: absolute;
  right: 6px;
  top: 8px;
}
.userEditModal #dropdown-autoclose-inside {
  width: 216px !important;
  height: 36px;
  left: 0px;
  text-align: left;
  overflow: auto;
}
.userEditModal .dropdown-menu {
  margin-top: -5px !important;
  margin-left: -5px;
}

.userEditModal .dropdown-toggle::after {
  position: absolute;
  right: 6px;
  top: 8px;
}

.filterModal #dropdown-autoclose-inside {
  width: 210px !important;
  height: 38px;
  margin-left: 1px !important;
  text-align: left;
  overflow: auto;
  border-radius: 4px;
  margin-top: 2px !important;
}
.filterModal .dropdown-toggle::after {
  position: absolute;
  right: 6px;
  top: 8px;
}
.filterModal .dropdown-menu {
  margin-top: -7px !important;
  margin-left: -5px;
}
.spinner {
  text-align: center;
}

#dropdown-autoclose-inside {
  background-color: #fff !important;
}

.msh-wrapper .tagCloseButton {
  display: inline-flex;
  vertical-align: top;
  position: absolute;
  top: 1px;
  right: 0px;
  color: rgba(0, 0, 0, 0.7);
  border: none;
  background: none;
  background-image: none;
  box-shadow: none !important;
}
.msh-wrapper .tagCloseButton:focus {
  outline: none;
  box-shadow: none;
}
.msh-wrapper .material-icons.plus-icon {
  color: #999999 !important;
  font-size: 38px;
  margin-right: -12px;
}
.msh-wrapper .custom-tag-input {
  color: #a91414;
  font-size: 14px;
}
.tags_wrapper .tag-btn {
  color: #ffffff;
  border-radius: 100px;
  border-color: #01579b !important;
  background: #01579b;
  height: 30px;
  border: none;
  padding-bottom: -9px;
  line-height: 29px;
  /* width: 109px; */
  font-size: 12px;
  margin: auto;
  width: auto;
}
.tags_wrapper .tag-clear-btn {
  padding: 0 !important;
  border-radius: 50%;
  background: #ffffff;
  color: #01579b;
  /* margin-left: 15px; */
  right: -30px !important;
  margin-top: 2px;
  margin-right: -3px;
  font-weight: 600;
}
.tags_wrapper .tag-label {
  font-size: 15px;
  padding-right: 31px;
  padding-left: 10px;
}

.tag_wrapper .tag-btn {
  color: #ffffff;
  border-radius: 100px;
  border-color: #01579b !important;
  background: #01579b;
  height: 20px;
  border: none;
  padding-bottom: -9px;
  line-height: 18px;
  /* width: 109px; */
  font-size: 12px;
  margin: auto;
  width: auto;
}

.tag_wrapper .tag-clear-btn {
  padding: 0 !important;
  border-radius: 50%;
  background: #ffffff;
  color: #01579b;
  /* margin-left: 15px; */
  right: -30px !important;
  margin-top: 1px;
  font-size: medium;
  margin-right: -4px;
  font-weight: 600;
}
.tag_wrapper .tag-label {
  padding-right: 5px;
}

.msh-wrapper .tag-sub-title {
  font-weight: 300;
  font-size: 14px;
  font-style: italic;
  line-height: 5px !important;
  color: rgba(0, 0, 0, 0.7);
}
.msh-req-error {
  display: block;
  top: -8px;
}

.brd-red {
  border: 1px solid rgba(220, 53, 69, 0.8) !important;
}
