.input_field {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin: 4px 0px 10px 0px;
  -webkit-align-items: center;
  align-items: center;
  justify-content: flex-start;
}

.input_field .labels {
  width: 25%;
  text-align: left;
  margin-right: 6px;
}

.input_field .input_holder {
  width: 75%;
}

.input_field .width-30 {
  width: 30%;
}

.input_holder input,
textarea {
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
}

.input_field em {
  color: rgba(220, 53, 69, 0.8);
}

/* for checkbox */

.input_holder_checkbox {
  margin-left: 20%;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #e2e2e2;
  outline: none;
  content: '✔';
}

input[type='checkbox']:disabled {
  background: #eeee;
}

input[type='checkbox']:checked {
  background: rgb(167, 167, 167);
  outline: none;
  content: '✔';
  width: 20px;
  height: 20px;
}

input[type='checkbox']:focus {
  outline: rgb(211, 18, 69) 1px solid;
}

.input_field strong {
  font-weight: bold !important;
}

.input_holder.no-label {
  width: 100%;
}
