.logo {
  position: fixed;
  height: 102px;
  width: 110px;
  margin: 20px;
}

.landing {
  width: 100vw;
  height: 100vh;
  background-color: #333;
  background-image: url(../../../images/guitar-bg.jpg);
  background-size: cover;
}

nav.top-nav.ext {
  width: 100%;
  text-align: right;
}

nav.top-nav.ext ul {
  list-style: none;
  margin: 0 40px 0 0;
  padding: 0;
}

nav.top-nav.ext ul li {
  display: inline-block;
}

nav.top-nav.ext ul li a {
  display: block;
  margin-right: 30px;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

nav.top-nav.ext ul li a:hover {
  color: #dc2c46;
}

nav.top-nav.ext ul li .lang-dropdown a {
  color: #000;
}

.over-bar {
  position: absolute;
  top: 34%;
  height: 200px;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 20px;
  z-index: 1;
}

@keyframes overbar-h1 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.over-bar h1 {
  font-size: 4.2em;
  animation-name: overbar-h1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes overbar-h2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.over-bar h2 {
  font-size: 1.6em;
  animation-name: overbar-h2;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

@keyframes bar {
  0% {
    height: 0px;
    opacity: 0;
  }

  100% {
    height: 200px;
    opacity: 0.7;
  }
}

.bar {
  position: absolute;
  top: 34%;
  height: 200px;
  width: 100%;
  color: #fff;
  background-color: #000;
  opacity: 0.7;
  animation-name: bar;
  animation-duration: 1s;
}

@keyframes btn-span {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.over-bar span {
  display: inline-block;
  margin-top: 10px;
  animation-name: btn-span;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

.over-bar button {
  width: 160px;
  color: #fff;
}

.over-bar button.access.btn {
  background-color: #dc2c46;
  margin-right: 5px;
}

.over-bar button.access.btn:hover {
  background-color: #f14760;
  box-shadow: 3px 3px 3px #111;
}

.over-bar button.log-in.btn {
  background-color: #5e30a8;
  margin-left: 5px;
}

.over-bar button.log-in.btn:hover {
  background-color: #7546c1;
  box-shadow: 3px 3px 3px #111;
}
