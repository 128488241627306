/* Find A Project */

ul.search-row {
  list-style: none;
  margin: 0 auto 40px auto;
  padding: 0;
}

ul.search-row li {
  display: inline-block;
  padding: 0;
}

ul.search-row li > input {
  margin: auto 10px;
  width: 100%;
}

ul.search-row li i {
  font-size: 20px;
}

ul.search-row.filters {
  margin-top: 20px;
}

ul.search-row.filters li {
  vertical-align: sub;
}

ul.search-row.filters li label {
  margin-left: 20px;
  font-weight: 700;
}

ul.search-row.filters button {
  margin-top: -3px;
  margin-left: 6px;
  box-shadow: 2px 2px 2px #f5f5f5;
}

ul.search-row.filters li i {
  font-size: 18px;
  margin-right: -5px;
}

th.status {
  width: 80px;
}

td.status {
  width: 80px;
  /* background-color: #f5f5f5; */
}

td.status i {
  font-size: 20px;
  color: #ff0000;
}

ul.results-controls {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

ul.results-controls .dropdown {
  margin: auto 6px;
}

ul.results-controls .dropdown .dropdown-toggle,
ul.results-controls .dropdown .dropdown-menu {
  color: #01578b;
  font-size: 0.9rem;
}

ul.results-controls span.viewing {
  padding-top: 7px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 1.25;
  color: #01578b;
  background-color: #f5f5f5;
  border: none;
}

.page-item.active .page-link {
  background-color: #01578b;
  border-color: #01578b;
  z-index: 0 !important;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #333;
  background-color: #f5f5f5;
}

table.table.find-project-table tbody tr:hover,
table.table.find-project-table tbody tr:hover td {
  cursor: pointer;
  background-color: #d9f3fe !important;
  transition: background-color 0.3s linear;
}

div.dropdown-menu.search-filters {
  padding: 20px;
  font-size: 0.9rem;
  width: 600px;
  overflow: visible;
  top: 0px;
  margin-top: 0px !important;
}

div.dropdown-menu.search-filters .dropdown-toggle {
  width: 160px;
  margin-bottom: 20px;
  text-align: left;
}

div.dropdown-menu.search-filters .dropdown-toggle::after {
  float: right;
  margin-top: 8px;
}

div.dropdown-menu.search-filters input[type='date'] {
  margin: 0px 8px;
}

div.dropdown-menu.search-filters button.btn {
  margin-right: 6px;
}

ul.row.search-row div.col-2 label {
  margin-top: 8px;
}

.find-project-filter .input_field .labels {
  width: 35%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  background: #fff;
}

.col-10.last-up-date {
  display: flex;
}

.find-project-filter {
  margin-bottom: 30px;
}

.search-bar {
  width: 100%;
  padding: 0px 12px;
}

.search-bar .input_field .input_holder {
  width: 100%;
}

.search-bar .input_field {
  margin: 0px;
}

.multi-select button {
  width: 100%;
  border: 1px solid #ddd;
  margin: 0px !important;
}

.full-width-table {
  padding: 0px 90px;
}

.col-1-small {
  flex: 0 0 5.333333%;
  max-width: 5.333333%;
}

.col-1-big {
  flex: 0 0 11.333333%;
  max-width: 11.333333%;
}

.find-a-project-table th.col-1.sortable {
  white-space: nowrap;
}

.pad-lft-20 {
  padding-left: 20px !important;
}

li.col-4.d-flex.justify-content-center.pagination-wrap {
  z-index: 1;
}

.gray-shade .material-icons {
  color: #807f7f !important;
}

a.dropdown-item.disabled {
  background: #eee;
  color: #aaa;
}
.last-up-date-to .labels {
  margin-left: 14px !important;
}
