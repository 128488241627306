#RequestAccesModal ul {
  margin: -30px 0px 0px 20px;
  padding: 0;
}

#RequestAccesModal ul li {
  list-style: none;
  line-height: 3em;
  margin: 0;
  padding: 0;
}

#RequestAccesModal label {
  width: 140px;
  display: inline-block;
}

#RequestAccesModal input {
  width: 280px;
  display: inline;
}

.modal-dialog {
  margin: 10% auto !important;
}

textarea#shareEmailsIds {
  border: none;
  background: #fff;
  outline: none;
  flex: 1 1;
  resize: none;
  white-space: pre;
  padding: 0px;
}

#shareProjectModal .submit-buttons.float-right {
  margin: 10px;
}
