div#AuditModal {
  max-width: 94% !important;
}

.display-filter {
  display: flex;
  align-items: center;
}

.table-sub-head {
  background: #d9f3fe;
  padding: 12px;
  font-weight: bold;
}

.round-step-circle {
  background: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  margin: 0px 2px;
}

div#AuditModal table thead {
  background: #f5f5f5;
}

div#AuditModal table thead th,
div#AuditModal table tbody td {
  padding-top: 12px;
  padding-bottom: 12px;
}

div#AuditModal .table {
  margin-bottom: 0;
}

div#AuditModal .modal-header {
  border-bottom: none;
  padding: 5px 20px;
}

td.completed-icon i.material-icons.success {
  color: green !important;
}

.filter-head {
  padding: 15px 0px;
  display: flex;
  flex-grow: 1;
}

.td-bottom-line-div {
  border-bottom: 1px solid #ccc;
  padding: 8px;
}

.audit-5-custom-td {
  padding: 0px !important;
}

.td-bottom-line-div span {
  padding: 4px;
  display: block;
}

.border-right {
  border-right: 1px solid #ccc;
}

.platform-wrapper span {
  min-height: 28px;
}

.platform-wrapper {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top !important;
}

.display-filter .col-auto {
  padding-right: 0px;
  padding-left: 0px;
}

.display-filter .col-auto label.col-form-label.tbd.text-nowrap.form-label {
  margin: auto 15px;
}

.light-blue {
  background: #d9f3fe;
}

div#AuditModal .modal-body {
  max-height: 640px;
  overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar {
  width: 12px;
}

.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
