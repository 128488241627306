.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
  margin-bottom: 10px;
  bottom: -5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #636363;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  border-radius: 50%;
  content: '';
  height: 12px;
  width: 12px;
  top: 2px;
  bottom: 2px;
  left: 2px;
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

input:checked + .slider {
  background-color: #01579b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #01579b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

input:disabled + .slider {
  background-color: #ccc;
  cursor: not-allowed;
}
input:disabled + .slider:before {
  background-color: #dee2e6;
}
