.takedown-modal-70w {
  max-width: 70% !important;
}

.takedown-modal-70w .sub-title {
  position: absolute;
  top: 56px;
}

.takedown-modal-70w .note {
  background: #d9f3fe;
  margin: 0;
  padding: 15px 20px;
}

.updated-text-clr {
  color: #a91414;
}
.custom-width-data {
  width: 224px;
}

.custom-width-id {
  width: 186px;
}

.takedown-modal-70w .table td,
.takedown-modal-70w .table th {
  flex-grow: 1;
  /* padding: 10px 35px; */
}

.takedown-modal-70w .modal-header {
  border-bottom: none;
  padding: 20px 35px;
}

.takedown-modal-70w .table tbody + tbody {
  border-top: none !important;
}

.takedown-modal-70w tr:nth-child(even) td {
  background-color: #f5f5f5;
}
.takedown-modal-70w .takedownProject th {
  background-color: white;
  padding: 20px;
}
.takedown-modal-70w .takedownProject td {
  padding: 20px;
}
.takedown-modal-70w .takedownProject thead {
  margin-bottom: 10px;
}
