@charset "utf-8";
/* CSS Document */

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 14px;
}

/*** Bootstrap Theming ***/

/* Template DOMs */

.container-fluid {
  padding: 0;
}

.left-nav {
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.content {
  margin-top: 45vh;
}

label.nested {
  width: 110px;
}

input.nested {
  width: 150px;
}

/* Custom Checkbox Inputs*/

input[type='checkbox'] {
  height: 22px;
  width: 22px;
}

input[type='date'] {
  width: 160px;
}

label.custom-checkbox {
  margin-bottom: 0;
  overflow: hidden;
}

/* Keep it vertically aligned in table rows */

.custom-checkbox {
  display: block;
  position: relative;
  height: 25px;
  width: 25px;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Hides the browsers version */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f5f5f5;
  border: solid 1px #ccc;
  border-radius: 2px;
}

/* Creates new checkmark */

.custom-checkbox:hover input ~ .checkmark {
  background-color: #666;
  border: solid 1px #666;
}

/* Adds hover color to the box */

.custom-checkbox input:checked ~ .checkmark {
  background-color: #666;
  border: solid 1px #666;
}

/* Adds blue background color to the box when checked */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Creates the checkmark indicator (hidden when not checked) */

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Show the checkmark when checked */

.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Styles the checkmark */

.check-cover {
  display: block;
  height: 25px;
  width: 25px;
  position: absolute;
  border: solid 1px red;
  z-index: 100;
  background-color: transparent;
}

.static-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 2px;
}

/* Creates new checkmark */

.custom-checkbox input:checked ~ .static-checkmark {
  display: block;
  background-color: #fff;
  border: solid 1px #fff;
}

/* Adds blue background color to the box when checked */

.static-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Creates the checkmark indicator (hidden when not checked) */

.custom-checkbox input:checked ~ .static-checkmark:after {
  display: block;
}

/* Show the checkmark when checked */

.custom-checkbox .static-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #666;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Styles the checkmark */

select.dropdown {
  padding: 0 8px !important;
}

.dropdown {
  padding: 0 0 !important;
}

.dropdown > .btn-primary {
  background-color: #f5f5f5;
  border: solid 1px #ccc;
  border-radius: 2px;
  color: #495057;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
}

.dropdown i.material-icons {
  padding: 0 6px 0 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff !important;
  background-color: #0069d9 !important;
}

.dropdown-toggle::after {
  margin: 0 0 -2px 10px;
}

.invalid-tooltip {
  position: absolute;
  z-index: 5;
  top: 0px;
  right: 0px;
  display: none;
  max-width: 100%;
  padding: 0.25rem;
  margin-top: 0;
  font-size: 0.675rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}
.multiselect-invalid-tooltip {
  position: absolute;
  z-index: 5;
  left: 328px;
  right: 172px;
  display: block;
  padding-right: 10px;
  max-width: 100%;
  padding: 0.25rem;
  margin-top: 0;
  font-size: 0.675rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

ul.request-form li > .invalid-tooltip {
  position: relative;
  top: -56px;
  right: -290px;
  width: 130px;
}

.tracks-table tr td {
  position: relative;
}

button.tooltip-btn {
  margin-right: 10px;
  margin-top: 8px;
}

/*	.form-group .invalid-tooltip {position:relative; top:-44px; right:-65%; max-width:200px;} */

/* Custom Default Links*/

a {
  color: #01579b;
  cursor: pointer;
}

.btn {
  font-size: 1em;
  border-radius: 2px;
}

/* .btn-sm {padding: .15rem .50rem .05rem .50rem; line-height: 0.3;} */

.btn-primary {
  background-color: #01579b;
  color: #fff;
  border: none;
}

.btn-secondary {
  color: #01579b;
  background-color: #f5f5f5;
  border: none;
}

.btn-secondary:hover {
  color: #01579b;
  background-color: #ccc;
  border: none;
}

.btn-secondary.action {
  vertical-align: middle;
  padding: 0.25rem;
  margin: 0 5px;
}

.btn-primary > i,
.btn-secondary > i {
  font-size: 20px;
}

.dropdown {
  display: inline-block;
  padding: 0px 8px;
}

.dropdown-menu {
  box-shadow: 2px 3px 8px #8c9396;
  max-height: 30rem;
  min-width: auto;
  /* Commented below to auto position dropdown up or down */
  /* overflow-y: auto;
  overflow-x: auto;
  transform: translate3d(5px, 35px, 0px) !important; */
}

.dropdown-menu > a.dropdown-item {
  font-size: 0.9em;
}

.multi-select.dropdown > .dropdown-menu > a.dropdown-item {
  font-size: 0.9em;
  line-height: 2em;
}

.multi-select.dropdown > .dropdown-menu > a.dropdown-item:hover,
.multi-select.dropdown > .dropdown-menu > a.dropdown-item:active {
  cursor: default;
  background-color: transparent;
}

.multi-select.dropdown > .dropdown-menu > a.dropdown-item .custom-checkbox {
  margin-right: 8px;
  vertical-align: middle;
}

.show > .btn-secondary.dropdown-toggle {
  background-color: #fff;
  border-color: #ccc;
  color: #333;
}

.form-control {
  font-size: 1em;
  display: inline-block;
  background-color: #f5f5f5;
  border-radius: 2px;
}

.landing {
  width: 100vw;
  height: 100vh;
  background-color: #333;
  background-image: url(../images/guitar-bg.jpg);
  background-size: cover;
}

.table thead {
  box-shadow: 0px 3px 3px #f5f5f5;
  z-index: 1;
}

.table thead th {
  border-bottom: 1px solid #dee2e6;
}

.table td,
.table th {
  border-top: none;
  border-bottom: 1px solid #dee2e6;
  padding: 0.5rem;
  vertical-align: middle;
}

.table td,
.table th {
  flex-grow: 1;
}

.table td.text-center button {
  margin-right: 6px;
}

.modal-dialog {
  margin: 10% auto !important;
}

/* Table Column widths no longer supported in Bootstrap 4 reliably */

.td-2 {
  max-width: 2%;
}

.td-5 {
  max-width: 5%;
}

.td-10 {
  max-width: 10%;
}

.td-20 {
  max-width: 20%;
}

.td-25 {
  max-width: 25%;
}

.td-30 {
  max-width: 30%;
}

.td-40 {
  max-width: 40%;
}

.td-50 {
  max-width: 50%;
}

.td-60 {
  max-width: 60%;
}

.td-70 {
  max-width: 70%;
}

.td-80 {
  max-width: 80%;
}

.td-90 {
  max-width: 90%;
}

h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.2em;
}

h3 .material-icons {
  font-size: 1.2em;
  margin-left: 10px;
  cursor: pointer;
}

.material-icons {
  display: inline-flex;
  vertical-align: top;
}

.purple-icon {
  color: #c208bb;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.modal-body .clearfix {
  font-size: 0.5em;
}

.modal-body span button {
  font-size: 0.7em;
  margin: 20px 3px;
  width: 100px;
}

/*** End Bootstrap Theming ***/

/* External Template */

.logo {
  position: fixed;
  height: 102px;
  width: 110px;
  margin: 20px;
}

nav.top-nav.ext {
  width: 100%;
  text-align: right;
}

nav.top-nav.ext ul {
  list-style: none;
  margin: 0 40px 0 0;
  padding: 0;
}

nav.top-nav.ext ul li {
  display: inline-block;
}

nav.top-nav.ext ul li a {
  display: block;
  margin-right: 30px;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

nav.top-nav.ext ul li a:hover {
  color: #dc2c46;
}

.over-bar {
  position: absolute;
  top: 34%;
  height: 200px;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 20px;
  z-index: 1;
}

select:disabled {
  background-color: #e9ecef !important;
}

span.btn-log {
  cursor: pointer;
}

@keyframes overbar-h1 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.over-bar h1 {
  font-size: 4.2em;
  animation-name: overbar-h1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

@keyframes overbar-h2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.over-bar h2 {
  font-size: 1.6em;
  animation-name: overbar-h2;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

@keyframes bar {
  0% {
    height: 0px;
    opacity: 0;
  }

  100% {
    height: 200px;
    opacity: 0.7;
  }
}

.bar {
  position: absolute;
  top: 34%;
  height: 200px;
  width: 100%;
  color: #fff;
  background-color: #000;
  opacity: 0.7;
  animation-name: bar;
  animation-duration: 1s;
}

@keyframes btn-span {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.over-bar span {
  display: inline-block;
  margin-top: 10px;
  animation-name: btn-span;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
}

.over-bar button {
  width: 160px;
  color: #fff;
}

.over-bar button.access.btn {
  background-color: #dc2c46;
  margin-right: 5px;
}

.over-bar button.access.btn:hover {
  background-color: #f14760;
  box-shadow: 3px 3px 3px #111;
}

.over-bar button.log-in.btn {
  background-color: #5e30a8;
  margin-left: 5px;
}

.over-bar button.log-in.btn:hover {
  background-color: #7546c1;
  box-shadow: 3px 3px 3px #111;
}

/* Internal Template */

nav.left-nav {
  display: flex;
  flex-direction: column;
  width: 260px !important;
  background-color: #000;
  text-align: left;
}

nav.left-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #000;
}

nav.left-nav ul li a {
  display: block;
  color: #fff;
  padding: 10px 10px 10px 20px;
  text-decoration: none;
  border-bottom: solid 1px #333;
}

nav.left-nav ul li a:hover {
  background-color: #333;
}

nav.left-nav ul li a.steps {
  background-color: #464646;
}

nav.left-nav ul li a.steps:hover {
  color: #333;
  background-color: #f5f5f5;
}

nav.left-nav span.count-circle {
  color: #333;
  font-weight: 700;
  padding: 3px 8px;
  margin: auto 6px auto 3px;
}

nav.left-nav span.left-nav-logo {
  display: block;
  margin: 20px auto 10px auto;
  background-image: url(../images/guardian-logo-sm.png);
  background-repeat: no-repeat;
  height: 130px;
  width: 134px;
}

nav.left-nav .fixed-left-nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0px 0px 0px 0px;
  background-image: url(../images/gradient-logo-bg.png);
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
  background-color: #000;
}

nav.top-nav.int {
  text-align: right;
}

nav.top-nav.int ul {
  list-style: none;
  margin: 0 40px 0 0;
  padding: 0;
}

nav.top-nav.int ul li {
  display: inline-block;
}

nav.top-nav.int ul li a {
  display: block;
  margin-right: 30px;
  padding: 8px;
  color: #333;
  text-decoration: none;
}

nav.top-nav.int ul li a:hover {
  color: #01579b;
}

nav.top-nav.int ul li a.help {
  background-color: #01579b;
  color: #fff;
}

.artist-label-name {
  font-size: 1.4em;
}

.row.step-description {
  margin: 40px 0px;
}

.row.step-description.review {
  margin: 40px 0px 0px 0px;
}

span.count-circle {
  background-color: #80d8ff;
  padding: 2px 10px !important;
  border-radius: 100%;
}

span.project-right {
  float: right;
}

span.project-status {
  vertical-align: sub;
  font-style: italic;
}

button.close-project {
  margin-left: 30px;
}

.required-ind {
  color: rgba(220, 53, 69, 0.8);
}

.save-buttons {
  text-align: right;
  padding-right: 0px;
  padding-top: 10px;
  padding-bottom: 60px;
  background-color: #fff;
}

.save-buttons .btn {
  margin-left: 10px;
}

ul.button-bar {
  position: absolute;
  left: 0;
  top: 187px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 0;
  padding: 4px;
}

ul.button-bar li {
  display: inline-block;
  border-bottom: 1px solid #fff;
}

ul.button-bar li:last-child {
  border-bottom: none;
}

ul.button-bar li button {
  padding: 14px;
  outline: none;
}

/* Release Information */

.album-art-drop {
  /* position: absolute; */
  border: solid 1px #ccc;
  background-color: #ff0000;
  height: 190px;
}

input#projectCoverArt {
  width: 190px;
  height: 190px;
}

.cover-art label {
  vertical-align: top;
  padding-top: 5px;
}

.notes-row {
  margin-left: 15px;
}

.notes-row > textarea {
  vertical-align: top;
  height: 120px;
}

.release-date > .invalid-tooltip {
  width: 100%;
  right: -200%;
}

label.col-form-label.tbd.text-nowrap.form-label {
  margin: auto 20px;
}

/* Project Contacts */

.additional-contacts label {
  margin-left: 15px;
  vertical-align: top;
}

.additional-contacts textarea {
  height: 120px;
  margin-left: -2px;
  width: 97.5%;
}

/* Audio Files & Track Information */

.audio-drop-area {
  border: none;
  width: 100%;
  height: 190px;
  background-color: #d9f3fe;
  border-radius: 2px;
}

.track-draggable-area {
  background-color: #d9f3fe;
  border: none;
  padding: 0.3rem 0.6rem;
  vertical-align: top;
}

.draggable-track {
  border-radius: 2px;
  background-color: #fff;
  margin: 7px 0;
  padding: 6px;
  box-shadow: none !important;
  cursor: grab;
}

.sticky-box .draggable-track,
.rightsSetTracks .draggable-track {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.draggable-track .track-multi-drag-check {
  margin-right: 8px;
}

.droppable-cell {
  height: 32px;
  border: dashed 1px #ccc;
  border-radius: 2px;
  padding: 6px;
  background-color: #f5f5f5;
}

.sortable-audio-file {
  width: 100%;
  min-width: 140px;
  padding: 6px;
  border-radius: 2px;
  background-color: #d9f3fe;
  vertical-align: middle;
  border: none !important;
  cursor: grab;
}

.sortable-audio-file .material-icons {
  padding-right: 10px;
}

.sortable-audio-file span {
  line-height: 24px;
}

ul.audio-file-info {
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

ul.audio-file-info li {
  width: 70%;
  padding-left: 8px;
}

ul.audio-file-info li:first-child,
ul.audio-file-info li:last-child {
  width: 15%;
}

span.loading-sm {
  margin-bottom: -4px;
}

.table-section {
  display: flex;
  flex-direction: column;
  margin: 0px 40px 40px 40px;
}

.table-section .nav-tabs {
  border-bottom: none;
}

.table-section .nav-link:first-child {
  margin-left: 30px;
}

.table-section .nav-link.active {
  border: none;
}

.table-section .tab-pane {
  background-color: #fff;
  padding: 30px;
}

/* Territorial Rights & Blocking Polcies */

div.table-responsive.d-flex.row.no-gutters {
  overflow: inherit;
}

.card-nav {
  text-align: right;
  margin-bottom: 10px;
}

.card-nav .dropdown {
  margin-left: 10px;
}

.set-card {
  margin-left: -20px;
  margin-bottom: 10px;
  padding: 1rem;
  background-color: #f5f5f5;
}

.set-card btn.dropdown {
  background-color: #fff;
}

.set-card table.table td {
  vertical-align: top;
  border-bottom: none;
}

.set-card .track-draggable-area {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.set-card .delete-rights-set {
  position: absolute;
  right: 0px;
}

.set-card input,
.set-card .dropdown {
  background-color: #fff;
  text-align: center;
}

th.text-right {
  text-align: right;
}

th button.tooltip-btn {
  float: none;
  vertical-align: baseline;
  z-index: 2000;
}

.tooltip {
  pointer-events: none !important;
}

span.drag-drop-arrow {
  display: block;
  height: 33px;
  position: absolute;
  padding-left: 50px;
  left: -38px;
  bottom: -44px;
  background-image: url(../images/drag-over-arrow.png);
  background-repeat: no-repeat;
  z-index: 10;
}

span.drag-drop-arrow > span {
  position: absolute;
  top: -20px;
  left: -10px;
  width: 160px;
  font-size: 0.7em;
  font-weight: 700;
}

ul.countries,
ul.tracks {
  margin: 0;
  padding: 0.75rem;
  list-style: none;
}

ul.tracks {
  min-width: 260px;
}

ul.countries li,
ul.tracks li {
  display: flex;
  padding-bottom: 0.6rem;
}

ul.countries li .dropdown-item,
ul.tracks li .dropdown-item {
  margin: 0rem 0rem;
}

ul.countries li:last-child,
ul.tracks li:last-child {
  padding-bottom: 0;
}

ul.countries li span,
ul.tracks li span {
  vertical-align: middle;
}

ul.countries li label.dropdown-item,
ul.tracks li label.dropdown-item {
  padding: 0.5rem 1.1rem;
}

.dropdown .custom-checkbox {
  display: inline-block;
}

.territory-tracks {
  width: 95% !important;
}

.option-text {
  color: #999;
  font-style: italic;
}

.required-text {
  color: #ff0000;
  font-style: italic;
}

.platform-sprite {
  display: block;
  height: 36px;
  width: 80px;
  background-image: url(../images/small-platform-sprite.png);
  background-repeat: no-repeat;
}

.platform-sprite.youtube {
  background-position: 0px 0px;
}

.platform-sprite.soundcloud {
  background-position: 0px -37px;
}

.platform-sprite.facebook {
  background-position: 0px -74px;
}

.platform-sprite.instagram {
  background-position: 0px -111px;
}

.platform-sprite.twitter {
  background-position: 0px -148px;
}

.dropdown.allowance {
  min-width: 100px;
}

div.sticky-box {
  position: sticky;
  top: 35vh;
  height: auto;
}

.dropdown.tracks-dropdown {
  width: 90% !important;
}

table.blocking-set .dropdown.tracks-dropdown {
  width: 100% !important;
}

.track-draggable-area {
  border: none !important;
}

table.blocking-set .track-draggable-area.territory-tracks {
  width: 100% !important;
}

.track-draggable-area.territory-tracks {
  width: 90% !important;
}

/* Review & Submit */

div.page-container.review-section {
  margin-top: 60px;
}

div.page-container.review-section label {
  font-weight: 700;
}

img.album-art {
  height: 160px;
  width: 160px;
  background-color: #f5f5f5;
}

.review-card {
  padding: 30px;
  background-color: #f5f5f5;
}

.review-card .tab-content table {
  background-color: #fff;
}

div.submit-project {
  margin: 30px auto 60px auto;
}

.submit-buttons button {
  margin-left: 6px;
}

/* Search Screens */

ul.search-row {
  list-style: none;
  margin: 0 auto 40px auto;
  padding: 0;
}

ul.search-row li {
  display: inline-block;
  padding: 0;
}

ul.search-row li > input {
  margin: auto 10px;
  width: 100%;
}

ul.search-row li i {
  font-size: 20px;
}

#collapsePanel.search-filters {
  margin-top: 20px;
}

#collapsePanel.search-filters div.card {
  position: relative;
  width: 66.7%;
  left: 16.62%;
  margin-top: 10px;
  border: none;
  background-color: #f5f5f5;
}

#collapsePanel.search-filters div.card #collapsePanel.search-filters {
  vertical-align: sub;
}

#collapsePanel.search-filters label {
  /* margin-left: 20px; */
  font-weight: 700;
}

#collapsePanel.search-filters button {
  margin-top: -3px;
  margin-left: 6px;
  box-shadow: 2px 2px 2px #f5f5f5;
}

#collapsePanel.search-filters .dropdown {
  margin-bottom: 20px;
}

#collapsePanel.search-filters input[type='date'] {
  margin-left: 6px;
  background-color: #fff;
}

#collapsePanel.search-filters i {
  /* font-size: 18px;
  margin-right: -5px; */
}

div.selected-filters {
  line-height: 2.7em;
}

div.selected-filters div.col-auto {
  width: 100px !important;
}

span.date-bubbles {
  display: inline-flex;
  white-space: nowrap;
}

.search-filters .card {
  border: none;
  background-color: #f5f5f5;
}

td.status {
  width: 80px;
  /* background-color: #f5f5f5; */
}

td.status i {
  font-size: 20px;
  color: red !important;
}

td.status i.material-icons.success {
  color: green !important;
}

td.status i.material-icons.warning {
  color: #f57f17 !important;
}
td.status i.bi.warning {
  color: #f57f17 !important;
}
ul.results-controls {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

ul.results-controls .dropdown {
  margin: auto 6px;
}

ul.results-controls span {
  /* margin-bottom: -60px; */
}

.dropdown.project-security-dropdown {
  width: 290px;
}

.audio-files-table tr td {
  position: relative;
}

ul.disc-track-buttons {
  list-style: none;
  display: inline-block;
  margin: 0 0 -36px 0;
  padding: 0;
}

ul.disc-track-buttons li {
  display: inline-block;
  float: right;
  margin: 0 5px;
}

table.search-table tr td,
table.search-table tr th {
  flex-grow: 1;
}

table.search-table tr:hover td {
  cursor: pointer;
  background-color: #d9f3fe !important;
}

th.sortable {
  cursor: pointer;
}

.page-container.review-section .tab-content {
  z-index: 10000;
}

.option-label {
  width: 100%;
}

/* filter css */

.assigned_inbox_filter_field {
  display: flex;
}

.assigned_inbox_filter_field .labels {
  width: 50% !important;
  padding-left: 20px;
}

.multi-select button#dropdownMenuButton:disabled {
  background-color: #6c757d !important;
}

.invisible {
  visibility: hidden;
}

.dropdown-menu.show {
  max-height: 500px;
  overflow: auto;
}

.dropdown-menu.show::-webkit-scrollbar {
  display: none;
}
