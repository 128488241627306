#BlockingPolicies .modal-body {
  text-align: center;
  font-size: 1.6rem;
}
#BlockingPolicies .modal-footer {
  justify-content: center;
  padding-bottom: 3rem;
}
#BlockingPolicies .modal-footer button {
  width: 100px;
}
